import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {fetchP5, fixThroughTeeTime} from "../Utils";
import SortableTable from '../../src/components/SortableTable';
import TableCell from "@mui/material/TableCell";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import TabPanel from "../components/TabPanel";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

function GolfStats(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [fetchUrl, ] = useState("/api/v1/golf/statspagedata?perm_num=");
    const [config, setConfig] = useState({});
    const [birthdays, setBirthdays] = useState([]);
    const [tournamentHistory, setTournamentHistory] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [golferHistory, setGolferHistory] = useState([]);
    const [golferSeason, setGolferSeason] = useState([]);
    const [selectedGolfer, setSelectedGolfer] = useState(null);
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'golfStats', props)
            .then(data => {
                    setConfig(data.data.config.data[0]);
                    setBirthdays(data.data.birthdays.data);
                    setTournamentHistory(data.data.tournament_history.data);
                    setIsLoading(false);
                }
            );
    }

    useEffect(() => {
        document.title = "Pick 5 Golf Stats";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (selectedGolfer) {
            fetchP5(props.getState().userName, props.getState().password, `/api/v1/golf/GetGolferTournamentHistory?gid=${selectedGolfer.ID}&perm_num=${config.Perm_Num}`, 'golfStatsTournamentHistory', props)
                .then(data => {
                        setGolferHistory(data.data.history.data);
                    }
                );
            fetchP5(props.getState().userName, props.getState().password, `/api/v1/golf/GetGolferYTDResults?gid=${selectedGolfer.ID}`, 'golfStatsSeason', props)
                .then(data => {
                        setGolferSeason(data.data.history.data);
                    }
                );
        }
    }, [selectedGolfer]);
    
    function renderLoading() {
        return <div>Loading</div>;
    }

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    function handleGolferClick(event, golferId) {
        setSelectedGolfer(tournamentHistory.filter((golfer) => golfer.ID === golferId).pop());
    }

    const headerCells = [
        {
            label: 'Name',
            id: 'golfer_name',
            numeric: false,
        },
        {
            label: 'Avg Finish',
            id: 'position',
            numeric: true,
        },
        {
            label: 'Cuts/Entries',
            id: 'CutsEntries',
            numeric: false,
        },
        {
            label: 'Rank',
            id: 'Rank',
            numeric: true,
        },
        {
            label: 'Tee Time',
            id: 'Tee_Time',
            numeric: true,
        },
        {
            label: 'BPR',
            id: 'Avg_Birdies',
            numeric: true,
        },
        {
            label: 'HPE',
            id: 'Holes_Per_Eagle',
            numeric: true,
        },
        {
            label: 'Cuts/Events',
            id: 'CutsEvents',
            numeric: true,
        },
        {
            label: 'Odds',
            id: 'Odds',
            numeric: true,
        },
        {
            label: 'CC',
            id: 'Consecutive_Cuts',
            numeric: true,
        }
    ];

    function renderHistoryRow(row, headerCell) {
        let value;
        switch (headerCell.id) {
            case 'Tee_Time':
                value = fixThroughTeeTime(row[headerCell.id]);
                break;
            case 'CutsEntries':
                value = `${row.cuts}/${row.entries}`;
                break;
            case 'CutsEvents':
                value = `${row.YTD_Cuts}/${row.YTD_Events}`;
                break;
            default:
                value = row[headerCell.id];
                break;
        }
        return (
            <TableCell align="center" key={`sortableTableCell${headerCell.id}-${row.group_num}`}>{value}</TableCell>
        );
    }    
    
    function sortableRowId(row) {
        return row.ID;
    }

    function renderGroupTab(groupNum) {
        return (
            <SortableTable
                rows={tournamentHistory.filter((golfer) => golfer.group_num === currentTab)}
                headCells={headerCells}
                defaultOrderBy="position"
                onRowSelect={handleGolferClick}
                renderRow={renderHistoryRow}
                selectable={true}
                selected={selectedGolfer ? [selectedGolfer.ID] : []}
                rowKey={sortableRowId}
            />
        );
    }
    
    function renderGolfersContainer() {
        return (
            <div className="GolfStatsGolfersContainer">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
                        <Tab label="Group 1"/>
                        <Tab label="Group 2"/>
                        <Tab label="Group 3"/>
                        <Tab label="Group 4"/>
                    </Tabs>
                </Box>
                <TabPanel value={currentTab} index={0}>{renderGroupTab(1)}</TabPanel>
                <TabPanel value={currentTab} index={1}>{renderGroupTab(2)}</TabPanel>
                <TabPanel value={currentTab} index={2}>{renderGroupTab(3)}</TabPanel>
                <TabPanel value={currentTab} index={3}>{renderGroupTab(4)}</TabPanel>
            </div>
        );
    }
    
    function renderGolferHistory() {
        return (
            <Fragment>
                <span>{selectedGolfer.golfer_name} at {config.Name}</span>
                <TableContainer component={Paper} className="GolfStatsTournamentHistoryContainer">
                    <Table size="small" className="Pick5DataTable">
                        <TableHead>
                            <TableRow key={selectedGolfer.ID + 'headerRow'}>
                                <TableCell key={selectedGolfer.ID + 'headerName'}>Year</TableCell>
                                <TableCell key={selectedGolfer.ID + 'headerPosition'}>Position</TableCell>
                                <TableCell key={selectedGolfer.ID + 'headerThrough'}>Through</TableCell>
                                <TableCell key={selectedGolfer.ID + 'headerScore'}>Score</TableCell>
                                <TableCell key={selectedGolfer.ID + 'headerRound'}>Round</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {golferHistory.map((event) =>
                                <TableRow key={event.year + 'ghRowTop'}>
                                    <TableCell align="left">{event.year}</TableCell>
                                    <TableCell>{event.position}</TableCell>
                                    <TableCell>{event.through}</TableCell>
                                    <TableCell>{event.score}</TableCell>
                                    <TableCell>{event.round}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        );
    }

    function renderGolferSeason() {
        return (
            <Fragment>
                <span>{selectedGolfer.golfer_name} YTD Results</span>
                <TableContainer component={Paper} className="GolfStatsSeasonContainer">
                    <Table size="small" className="Pick5DataTable">
                        <TableHead>
                            <TableRow key={selectedGolfer.ID+'headerRow'}>
                                <TableCell key={selectedGolfer.ID+'headerDate'}>Date</TableCell>
                                <TableCell key={selectedGolfer.ID+'headerTournament'}>Tournament</TableCell>
                                <TableCell key={selectedGolfer.ID+'headerPosition'}>Position</TableCell>
                                <TableCell key={selectedGolfer.ID+'headerThrough'}>Through</TableCell>
                                <TableCell key={selectedGolfer.ID+'headerScore'}>Score</TableCell>
                                <TableCell key={selectedGolfer.ID+'headerRound'}>Round</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {golferSeason.map((event) =>
                                <TableRow key={event.Start_Date+'ghRowTop'}>
                                    <TableCell align="left">{event.Start_Date}</TableCell>
                                    <TableCell>{event.Tournament_Name}</TableCell>
                                    <TableCell>{event.Position}</TableCell>
                                    <TableCell>{event.Through}</TableCell>
                                    <TableCell>{event.Score}</TableCell>
                                    <TableCell>{event.Round}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        );
    }
    
    function renderBirthdays() {
        return (
            <Fragment>
                <span>Birthdays</span>
                <TableContainer component={Paper} className="GolfStatsBirthdaysContainer">
                    <Table size="small" className="Pick5DataTable">
                        <TableHead>
                            <TableRow key={'birthdayHeaderRow'}>
                                <TableCell key={'birthdayHeaderName'}>Name</TableCell>
                                <TableCell key={'birthdayHeaderBirthday'}>Birthday</TableCell>
                                <TableCell key={'birthdayHeaderAge'}>Age</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {birthdays.map((birthday) =>
                                <TableRow key={birthday.Golfer_Name+'ghRowTop'}>
                                    <TableCell align="left">{birthday.Golfer_Name}</TableCell>
                                    <TableCell>{birthday.Golfer_Birthday}</TableCell>
                                    <TableCell>{birthday.Golfer_Age}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        );
    }
    
    return (
        <div>
            {isLoading ? renderLoading() : (
                <div>
                    <h4>{config.Name} History</h4>
                    <div className="GolfStatsDataContainer">
                        {renderGolfersContainer()}
                        <div>
                            {selectedGolfer ? renderGolferHistory() : ''}
                        </div>
                        <div>
                            {selectedGolfer ? renderGolferSeason() : ''}
                        </div>
                        <div>
                            {renderBirthdays()}
                        </div>
                    </div>                        
                </div>  
            )}
        </div>
    );
}

export default GolfStats;