import './App.css';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {fetchP5} from "./Utils";
import Button from '@mui/material/Button';
import {ThemeProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {lightTheme, darkTheme} from "./components/UI/Theme";
import {TextField} from "@mui/material";

function App(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [, setIsLoggedIn] = useState(false);
    const [theme, setTheme] = useState('lightTheme');
    const [signInError, setSignInError] = useState('');
    
    // List each contest here that has been converted to React
    let reactContests = ['Underdogs', 'bbSquares', 'Golf', 'Football', 'FootballWins', 'bbAuction'];

    function signIn(uname, pwd) {
        fetchP5(uname, pwd, "/api/v1/na/signin")
            .then(data => {
                if (data.error) {
                    setSignInError(data.error.message);
                    setIsLoggedIn(false);
                    localStorage.removeItem('auth');
                    localStorage.removeItem('pick5#!#auth');
                }
                else {
                    props.mergeState({
                        isLoggedIn: true,
                        group: data.data.group_number,
                        userContests: data.data.user_contests
                    });
                    const auth = btoa(props.getState().userName + ':' + props.getState().password) + ':' + data.data.group_number;
                    localStorage.setItem('auth', auth);
                    localStorage.setItem('pick5#!#auth', '"' + btoa(props.getState().userName + ':' + props.getState().password) + ':' + data.data.group_number + '"');
                    setSignInError('');
                    setIsLoggedIn(true);
                }
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (props.getState().isLoggedIn) {
            setIsLoading(true);
            setIsLoggedIn(true);
            signIn(props.getState().userName, props.getState().password);
        }
        else {
            setIsLoading(false);
            setIsLoggedIn(false);
        }

        const userTheme = localStorage.getItem('theme');
        if (userTheme !== null) {
            setTheme(userTheme);
        }
    }, []);
    
    function signOut() {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/utils/logout')
            .then((data) => {
                if (data.error) {
                    
                }
                else {
                    localStorage.removeItem('auth');
                    localStorage.removeItem('pick5#!#auth');
                    props.mergeState({
                        userName: '',
                        password: '',
                        group: '',
                        isLoggedIn: false
                    });
                    setIsLoggedIn(false);
                }
            });
    }
    
    function renderLoading() {
        return (<div>Loading</div>);
    }

    function renderLoggedIn() {
        return (
            <div>
                <h4>Hello {props.getState().userName}
                    <Button 
                        variant="contained" 
                        size="small"
                        onClick={signOut}
                        className="SignInOutButton"
                    >
                        Sign Out
                    </Button>
                </h4>
                {props.getState().userContests.map(( contestName ) => {
                    // Add converted to react contests to this list as they are converted
                    if (reactContests.includes(contestName)) {
                        const lnk = "/" + contestName.toLowerCase();
                        return (<Link className="ContestLink" to={lnk} key={'link-' + contestName}>{contestName}</Link>)
                    }
                    else {
                        // link to old HTML contest pages
                        const lnk = "https://www.pickfive.org/" + contestName.toLowerCase() + "/index.html";
                        return (<a className="ContestLink" href={lnk} key={'href-' + contestName}>{contestName}</a>)
                    }
                })}
            </div>
        );
    }
    
    function renderLoggedOut() {
        return (
            <div>
                <TextField
                    id="txtUserName"
                    helperText="username"
                    size="small"
                    onInput={e => props.mergeState({
                        userName: e.target.value
                    })}
                />
                <TextField
                    id="txtPassword"
                    helperText="password"
                    type="password"
                    size="small"
                    onInput={e => props.mergeState({
                        password: e.target.value
                    })}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            setIsLoading(true);
                            signIn(props.getState().userName, props.getState().password);
                        }
                    }}
                />
                <Button 
                    variant="contained"
                    size="small"
                    onClick={() => {
                        setIsLoading(true);
                        signIn(props.getState().userName, props.getState().password);
                    }}
                    className="SignInOutButton"
                >
                    Sign In
                </Button>
                {signInError ?(<p>{signInError}</p>) : ''}
            </div>
        );
    }
    
    return (
        <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
            <CssBaseline />
            <div className="App">
                <h1>Welcome to Pick 5</h1>
                {isLoading ? (
                    renderLoading()
                ) : (
                    props.getState().isLoggedIn ? renderLoggedIn() : renderLoggedOut()
                )}
            </div>
        </ThemeProvider>
    );
}

export default App;
