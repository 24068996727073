import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Football from 'Football/Football';
import Underdogs from 'Underdogs/Underdogs';
import Golf from 'Golf/Golf';
import FootballMakePicks from 'Football/MakePicks';
import FootballHistory from 'Football/History';
import {getAuth} from "Utils";
import GolfMakePicks from 'Golf/MakePicks';
import GolfRules from 'Golf/Rules';
import GolfStats from 'Golf/Stats';
import BBSquares from 'BBSquares/BBSquares'
import AccountSettings from "Utils/AccountSettings";
import SetPassword from "Utils/SetPassword";
import FootballRules from "Football/Rules";
import FootballAllTime from "./Football/AllTime";
import FootballWins from "./FootballWins/FootballWins";
import FootballWinsRules from "./FootballWins/Rules";
import Ad from "./Ad/Ad";
import SSF from "./SSF/SSF";
import BBAuction from "./bbAuction/BBAuction";
import Auction from "./bbAuction/Auction";

const initialState = {
    isLoggedIn: false
};
const auth = getAuth();
if (auth) {
    initialState.userName = auth.userName;
    initialState.password = auth.password;
    initialState.group = auth.group;
    initialState.isLoggedIn = true;
}

function Store() {
    this.state = initialState;
}
Store.prototype.mergeState = function(partialState) {
    Object.assign(this.state, partialState);
};
Store.prototype.getState = function() {
    return this.state;
}
const myStore = new Store();

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/"                  element={<App                  mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="/index.html"        element={<App                  mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="football"           element={<Football             mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="football/makepicks" element={<FootballMakePicks    mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="football/history"   element={<FootballHistory      mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="football/rules"     element={<FootballRules        mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="football/alltime"   element={<FootballAllTime      mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="footballwins"       element={<FootballWins         mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="footballwins/rules" element={<FootballWinsRules    mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="golf"               element={<Golf                 mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="golf/makepicks"     element={<GolfMakePicks        mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="golf/rules"         element={<GolfRules            mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="golf/stats"         element={<GolfStats            mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="underdogs"          element={<Underdogs            mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="bbsquares"          element={<BBSquares            mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="bbauction"          element={<BBAuction            mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="bbauction/auction"  element={<Auction              mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="accountsettings"    element={<AccountSettings      mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="setpassword"        element={<SetPassword          mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="ad"                 element={<Ad                   mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
            <Route path="ssf"                element={<SSF                  mergeState={myStore.mergeState.bind(myStore)} getState={myStore.getState.bind(myStore)}/>}/>
        </Routes>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
